export const useTags = () => {
    const tagMapping = {
        CANETADA: { label: 'Canetada', type: 'list', grade: 0 },
        JUSTINTIME: { label: 'Diária', type: 'list', grade: 0 },
        INCREMENTAL: { label: 'Incremental', type: 'list', grade: 0 },
        QUALITY_PADRAO: { label: 'Comum', type: 'quality', grade: 1 },
        QUALITY_PREMIUM: { label: 'Selecionado', type: 'quality', grade: 2 },
        QUALITY_FAAS: { label: 'Selecionado', type: 'quality', grade: 2 },
        MATURATION_INCOMPLETA: { label: 'Verde', type: 'maturation', grade: 1 },
        MATURATION_PADRAO: { label: 'De Vez', type: 'maturation', grade: 2 },
        MATURATION_COMPLETA: { label: 'Madura', type: 'maturation', grade: 3 },
        PREDICTIVE_ITEM: { label: 'Preditiva', type: 'list', grade: 0 },
        PREDICTIVE: { label: 'Preditiva', type: 'list', grade: 0 },
    }

    const formatTag = (tag: string) => ({
        ...tagMapping[tag.toUpperCase()] || {},
        value: tag,
    })

    const tagFromLabel = (label: string) => {
        const tag = Object.entries(tagMapping).find(([, value]) => value.label === label)
        return tag ? tag[1] : null
    }

    return { formatTag, tagFromLabel }
}
