import type { Location, Page } from '~~/types/api-common'

export const useShopperService = () => {
    const getShopperById = async (id: string): Promise<ShopperResponse | null> => {
        const data = await api<ShopperResponse>(
            `/shopper/${id}`,
        )
        return data
    }

    const getShopperByEmail = async (email: string): Promise<ShopperResponse | null> => {
        const data = await api<Page<ShopperResponse>>(
            '/shopper/', { params: { email } },
        )
        console.log(data)
        return data.items.pop() || null
    }

    const listShoppers = async (): Promise<ShopperResponse[]> => {
        const data = await api<Page<ShopperResponse>>('/shopper/', { params: { size: 1000 } })
        return data.items
    }

    return { getShopperById, getShopperByEmail, listShoppers }
}

export interface ShopperResponse {
    name: string
    phone_number: string
    email?: any
    id_location: string
    shopping_params: ShoppingParams
    shopping_tags: string[]
    rules: Rule[]
    id_shopper: string
    location: Location
}

interface Rule {
    object_type: string
    object_uuid: string
    rule_type: string
}

interface ShoppingParams {
    service_time: number
    max_service_time: number
    weight_capacity: number
}
