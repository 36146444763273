export enum MeasureType {
    Box = 'box',
    Weight = 'weight',
    Unit = 'unit',
}

export enum PriceType {
    TotalPrice = 'total_price',
    UnitPrice = 'unit_price',
}
export interface BuyEvent {
    id?: string
    measureType: MeasureType
    wholesaleQuantity: number
    wholesaleQuantityUnit: string
    quantity: number
    price: number
    isJustInTime: boolean
    /**
     * ID of the Provider where the purchase was made
     */
    provider: string
    /**
     * ID of the Shopper who made the purchase
     */
    shopper: string
    priceType: PriceType

}
