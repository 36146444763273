import type { APIFilter } from '../api'
import type { ProcurementItemPurchaseResponse } from './procurement-item-purchase-service'
import { mapProcurementItemPurchaseResponseToProcurementItemPurchase } from './procurement-item-purchase-service'
import type { RawSku } from '~~/types/raw-sku'
import type { Page } from '~~/types/api-common'
import type { ProcurementItem } from '~~/types/procurement'
import { ProcurementItemStatus } from '~~/types/procurement'
import { RawSku } from './raw-sku-service'

const FILTER_OLD_SL_TAGS = ['bbc', 'lojas', 'folhas', 'pedra']

export const useProcurementItemService = () => {
    const fetchProcurementItems = async ({
        page = 1, size = 100, empty = true, toBePurchasedAt, idShopper, idLocation,
    }: FetchProcurementItemsOptions) => {
        const params: Record<string, any> = {
            page,
            size,
            empty,
            to_be_purchased_at: toBePurchasedAt,
            id_shopper: idShopper,
            id_location: idLocation,
        }

        const data = await api<ListProcurementItemResponse>(
            '/shoppingnon/purchase_list/',
            { params },
        )

        const mapResponseItemsToModel = (item: ProcurementItemResponse): ProcurementItem => {
            let tags = [
                ...(item.tags || []),
                item?.quality_type?.name ? `quality_${item?.quality_type?.name}` : null,
                item?.maturation_type?.name ? `maturation_${item?.maturation_type?.name}` : null,
            ].filter(tag => Boolean(tag) && tag !== 'DARK_LAUNCH')

            for (const tag of tags) {
                if (tag === 'Incremental' && tags.includes('JustInTime')) {
                    tags = tags.filter(tag => tag !== 'JustInTime')
                }
            }

            return {
                id: item.id_procurement_item,
                name: String(item.raw_product?.product_name),
                wholesale: {
                    unit: item.raw_product?.quantity_wholesale_unit,
                    quantity: item.raw_product?.quantity_wholesale,
                },
                buyEvents: item.purchases.map(mapProcurementItemPurchaseResponseToProcurementItemPurchase),
                notFound: item.status === ProcurementItemStatus.NotFound,
                image: item.raw_product?.image,
                tags,
                boxes: (() => {
                    const { total_units, total_weight } = item
                    return Math.ceil((item.raw_product?.quantity_wholesale_unit === 'KG' ? total_weight : total_units) / (item.raw_product?.quantity_wholesale || 1))
                })(),
                units: item.total_units,
                weight: item.total_weight,
                quantity: (() => {
                    if (item.raw_product?.quantity_wholesale_unit === 'KG') {
                        return item.total_weight
                    }
                    return item.total_units
                })(),
                averageUnitWeight: item.raw_product?.average_unit_weight,
                status: item.status,
                updatedAt: item.updated_at,
                productId: item.raw_product?.id_raw_product,
                qualityTypeId: item.quality_type?.id_quality_type,
                maturationTypeId: item.maturation_type?.id_maturation_type,
                raw_sku: item.raw_sku,
                inwardLot: item.raw_sku?.inward,
            }
        }

        return data.items.map(mapResponseItemsToModel).filter(item => !FILTER_OLD_SL_TAGS.some(tag => item.tags.includes(tag)))
    }

    const createProcurementItem = async (procurementItem: ProcurementItemCreate) => {
        const data = await api.post<CreateProcurementItemResponse>(
            '/procurement_item/',
            procurementItem,
        )
        return data
    }

    const updateProcurementItem = async (id: string, procurementItem: ProcurementItemUpdate) => {
        const data = await api.patch<ProcurementItemResponse>(
            `/procurement_item/${id}`,
            procurementItem,
        )
        return data
    }

    return { fetchProcurementItems, createProcurementItem, updateProcurementItem }
}

type ListProcurementItemResponse = Page<ProcurementItemResponse>
type CreateProcurementItemResponse = Array<ProcurementItemResponse>

interface ProcurementItemResponse {
    id_procurement_item: string
    updated_at: string
    raw_product?: RawProductResponse
    to_be_purchased_at: string
    tags?: string[]
    status: ProcurementItemStatus
    total_units: number
    total_weight: number
    quality_type?: QualityTypeResponse
    maturation_type?: MaturationTypeResponse
    purchases: ProcurementItemPurchaseResponse[]
    raw_sku: RawSku
}

interface RawProductResponse {
    id_raw_product?: string
    product_name: string
    quantity_wholesale: number
    quantity_wholesale_unit: string
    average_unit_weight: number
    image?: any
}

interface MaturationTypeResponse {
    name: string
    id_maturation_type: string
}

interface QualityTypeResponse {
    name: string
    id_quality_type: string
}

interface ProcurementItemCreate {
    id_raw_product: string
    // id_quality_type: string | null
    // id_maturation_type: string | null
    id_shopper: string | null
    id_location: string | null
    total_units: number | null
    total_weight: number | null
    tags: Array<string>
}

type ProcurementItemUpdate = Partial<
ProcurementItemCreate & {
    status: ProcurementItemStatus
}
>

export interface FetchProcurementItemsOptions {
    filter?: APIFilter
    page?: number
    size?: number
    empty?: boolean
    with_inward_lot?: boolean
    toBePurchasedAt?: string
    idShopper?: string
    idLocation?: string
}
