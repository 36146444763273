import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import type { Provider } from './services/provider-service'
import { useProviderService } from './services/provider-service'

export const useProviderStore = defineStore('provider', {
    persist: true,
    state: () => ({
        providers: null,
        providersAbleToShop: null
    } as ProviderStoreState),
    actions: {
        async fetchProviders() {
            const { shopper } = storeToRefs(useShopperStore())
            log(`Fetching providers from location ${shopper.value?.id_location}`)

            const { listProviders } = useProviderService()
            const providers = await listProviders(shopper.value?.id_location, false)

            if (providers === null) {
                log('No providers found')
            }

            this.providers = providers
        },
        async fetchProvidersAbleToShop() {
            const { shopper } = storeToRefs(useShopperStore())
            log(`Fetching providers abe to Shop from location ${shopper.value?.id_location}`)

            const { listProviders } = useProviderService()
            const providersAbleToShop = await listProviders(shopper.value?.id_location, true)

            if (providersAbleToShop === null) {
                log('No providers found')
            }

            this.providersAbleToShop = providersAbleToShop
        },
        async createProvider(providerName: string) {
            const { shopper } = storeToRefs(useShopperStore())
            log(`Creating provider ${providerName} in location ${shopper.value?.id_location}`)

            const { createProvider } = useProviderService()
            const providerCreated = await createProvider({ name: providerName, id_location: shopper.value?.id_location })

            if (providerCreated === null) {
                log('No provider created')
            }

            this.providers = [...this.providers, providerCreated]

            return providerCreated
        },
    },
})

function log(...args: any[]) {
    if (__DEV__) { console.info('[Provider Store]', ...args) }
}

interface ProviderStoreState {
    providers: Provider[]
    providersAbleToShop: Provider[]
}

if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useProviderStore, import.meta.hot)) }
