import { acceptHMRUpdate, defineStore } from 'pinia'
import type { ShopperResponse } from './services/shopper-service'
import { useShopperService } from './services/shopper-service'
import type { Location } from '~~/types/api-common'

const { fetchProviders, fetchProvidersAbleToShop} = useProviderStore()

export const useShopperStore = defineStore('shopper', {
    state: () => ({
        userShopper: null,
        impersonatingShopper: null,
        impersonatingShopperId: null,
    } as ShopperStoreState),
    getters: {
        userIsAShopper: state => !!state.userShopper || !!state.impersonatingShopper,
        shopperId: state => state.userShopper?.id_shopper,
        isImpersonatingShopper: state => !!state.impersonatingShopperId,
        impersonatingShopperName: state => state.impersonatingShopper?.name,
        shopper: state => state.impersonatingShopper || state.userShopper,
    },
    actions: {
        async clearImpersonation() {
            const impersonatingShopperIdStorage = useLocalStorage<string | null>('impersonatingShopperId', null)
            impersonatingShopperIdStorage.value = null
            this.impersonatingShopper = null
            this.impersonatingShopperId = null
            await this.getUserShopper()
        },

        async impersonate(shopper: ShopperResponse) {
            const impersonatingShopperIdStorage = useLocalStorage<string | null>('impersonatingShopperId', null)
            this.impersonatingShopperId = shopper.id_shopper
            this.impersonatingShopper = shopper
            impersonatingShopperIdStorage.value = this.impersonatingShopperId
            fetchProviders()
            fetchProvidersAbleToShop()
        },

        async refreshImpersonation(id: string) {
            const { getShopperById } = useShopperService()
            const shopper = await getShopperById(id)
            if (shopper === null) {
                log(`No shopper found to impersonate with id: ${id}`)
                return
            }

            this.impersonatingShopper = shopper
            this.impersonatingShopperId = id
        },

        async getUserShopper() {
            log('Fetching Shopper ID from email')
            const user = useSupabaseUser()
            const userEmail = user.value.email
            if (!userEmail) {
                throw new Error('Couldn\'t get user\'s email.')
            }
            const { getShopperByEmail } = useShopperService()
            const shopper = await getShopperByEmail(userEmail)
            if (shopper === null) {
                log('No shopper attached to user')
                return
            }

            this.userShopper = shopper
            fetchProviders()
            fetchProvidersAbleToShop()
        },

        async listShoppers() {
            log('Fetching all shoppers')
            const { listShoppers } = useShopperService()
            const shoppers = await listShoppers()
            return shoppers
        },
    },
})

function log(...args: any[]) {
    if (__DEV__) { console.info('[Shopper Store]', ...args) }
}

interface ShopperStoreState {
    userShopper: ShopperResponse | null
    impersonatingShopper: ShopperResponse | null
    impersonatingShopperId: string | null
}

export interface Shopper {
    email: string
    id_location: string
    id_shopper: string
    name: string
    phone_number: string
    rules: any[]
    shopping_params: any
    shopping_tags: any[]
    location: Location
}

if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useShopperStore, import.meta.hot)) }
