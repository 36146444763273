import { MeasureType, PriceType } from '~~/types/buy-event'
import type { BuyEvent } from '~~/types/buy-event'

const getMeasureTypeFromResponse = (response: ProcurementItemPurchaseResponse): MeasureType => {
    if (response.units > 0) {
        return MeasureType.Unit
    }
    else if (response.boxes > 0) {
        return MeasureType.Box
    }
    return MeasureType.Weight
}

export const mapProcurementItemPurchaseResponseToProcurementItemPurchase = (purchase: ProcurementItemPurchaseResponse): BuyEvent => ({
    id: purchase.id_procurement_item_purchase,
    measureType: getMeasureTypeFromResponse(purchase),
    wholesaleQuantity: purchase.wholesale_quantity,
    quantity: purchase.units + purchase.weight + purchase.boxes,
    price: purchase.price,
    provider: purchase.id_provider,
    shopper: purchase.id_shopper,
})

const mapPayloadIntoProcurementItemPurchaseRequest = (itemPurchase: BuyEvent, procurementItemId: string): ProcurementItemPurchaseRequest => {
    const priceType = itemPurchase.priceType
    const units = itemPurchase.measureType === MeasureType.Unit ? itemPurchase.quantity : 0
    const weight = itemPurchase.measureType === MeasureType.Weight ? itemPurchase.quantity : 0
    const boxes = itemPurchase.measureType === MeasureType.Box ? itemPurchase.quantity : 0

    let price = itemPurchase.price
    let total_price = null
    const quantity = boxes || units || weight || 1

    if (priceType === PriceType.TotalPrice) {
        total_price = price
        price = parseFloat((total_price / quantity).toFixed(2))
    } else {
        price = price
        total_price = parseFloat((price * quantity).toFixed(2))
    }

    return {
        id_procurement_item: procurementItemId,
        price,
        total_price,
        units,
        weight,
        boxes,
        wholesale_quantity: itemPurchase.wholesaleQuantity,
        id_provider: itemPurchase.provider,
        id_shopper: itemPurchase.shopper,
        ...(itemPurchase.wholesaleQuantityUnit ? { quantity_wholesale_unit: itemPurchase.wholesaleQuantityUnit } : {}),
    }
}

export const useProcurementItemPurchaseService = () => {
    const addProcurementItemPurchase = async ({ itemPurchase, procurementItemId }: { itemPurchase: BuyEvent; procurementItemId: string }) => {
        const payload = mapPayloadIntoProcurementItemPurchaseRequest(itemPurchase, procurementItemId)
        log(payload)

        const data = await api.post<ProcurementItemPurchaseResponse>(
            '/procurement_item_purchase/',
            payload,
        )

        return mapProcurementItemPurchaseResponseToProcurementItemPurchase(data)
    }

    function log(...args: any[]) {
        if (__DEV__) { console.info('[Procurement Item Purchase]', ...args) }
    }

    const updateProcurementItemPurchase = async ({ itemPurchase, procurementItemId }: { itemPurchase: BuyEvent; procurementItemId: string }) => {
        const payload = mapPayloadIntoProcurementItemPurchaseRequest(itemPurchase, procurementItemId)

        const data = await api.patch<ProcurementItemPurchaseResponse>(
            `/procurement_item_purchase/${itemPurchase.id}`,
            payload,
        )

        return mapProcurementItemPurchaseResponseToProcurementItemPurchase(data)
    }

    const deleteProcurementItemPurchase = async ({ itemPurchase }: { itemPurchase: BuyEvent }) => {
        await api.delete<ProcurementItemPurchaseResponse>(
            `/procurement_item_purchase/${itemPurchase.id}`,
        )
    }

    return { addProcurementItemPurchase, updateProcurementItemPurchase, deleteProcurementItemPurchase }
}

interface ProcurementItemPurchaseRequest {
    id_procurement_item: string
    price: number
    total_price: number
    units: number
    weight: number
    boxes: number
    wholesale_quantity: number
    quantity_wholesale_unit?: string
    wholesale_recipient_price?: number
    id_provider: string
    is_quotation?: boolean
    id_shopper?: string
}

export interface ProcurementItemPurchaseResponse extends ProcurementItemPurchaseRequest {
    id_procurement_item_purchase: string
    created_at: string
    updated_at: string
    deleted_at?: any
}
