import type { Page } from '~~/types/api-common'

export const useProviderService = () => {
    const listProviders = async (locationId?: string, syncedToNc?: boolean): Promise<Provider[] | null> => {
        const params: any = {
            size: 1000,
        }

        if (syncedToNc) {
            params.synced_to_nc = true
        }

        if (locationId) {
            params.id_location = locationId
        }

        try {
            const data = await api<Page<Provider>>(
                '/provider/', { params },
            )
            return data.items
        }
       
        catch (e) {
            console.error(e)
            return null
        }
    }

    const createProvider = async (provider: ProviderCreate) => {
        const data = await api.post<Provider>('/provider/', provider)
        return data
    }

    return { listProviders, createProvider }
}

export interface Provider {
    name: string
    id_provider: string
    street: string
    state: string
    city: string
    cep: string
    number: string
    complement: string
    id_location?: any
}

export type ProviderCreate = Pick<Provider, 'name' | 'id_location'>
